
html, body {
    height: 100vh;
    margin: 0;
}
  
.full-height {
  height: 100vh;
}

.top-right {
  position: absolute;
  right: 10px;
  top: 18px;
}

.content {
  text-align: center;
}

.links > a {
  padding: 0 25px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: .1rem;
  text-transform: uppercase;
}

.m-b-md {
  margin-bottom: 30px;
}

.img-thumbnail {
  border: 0 none;
  box-shadow: none;
}